<template>
  <v-dialog
      max-width="100%"
      v-model="dialog"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
          name="Ajouter un manuel"
          height="44px"
          color="primary"
          dark
          class="AccessButton pl-3 pr-1"
          v-bind="attrs"
          v-on="on"
          tile
          elevation="0"
          fab
          width="auto"
          :outlined="Ishovered"
          @mouseover="hovered()"
          @mouseleave="notHovered()"
          :disabled="disable"
      >
        {{ action }}
        <v-spacer/>
        <v-icon
            class="ml-5"
            :class="boutonMove"
        >
          mdi-plus-circle-outline
        </v-icon>
      </v-btn>
    </template>
    <template
        color="white"
        v-slot:default="dialog"
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2 primary--text">
          <h1 class="text-h4 font text-break">
            Catalogue des références éditeurs
          </h1>
        </v-card-title>
        <v-card-text>
          <v-data-table
              :footer-props="{itemsPerPageOptions,itemsPerPageText}"
              :header-props="{ sortByText: `Trier par`}"
              :headers="headers"
              :items="libraryBooks"
              item-key="refOffreDivalto"
              class="elevation-0 ma-1 pa-1 mt-4"
              :page="page"
              :pageCount="numberOfPages"
              :options.sync="options"
              :server-items-length="totalLibraryBooks"
              :loading="loading"
              loading-text="Chargement des éléments"
              mobile-breakpoint="850"
          >
            <template v-slot:[`item.ouvrage.nom`]="{ item }">
              <span>{{ item.ouvrage.nom }}</span>
            </template>
            <!-- Matiere ------------------------------------------------------------------------------->
            <template v-slot:[`item.ouvrage.matiere`]="{ item }">
              <div
                  :key="Keytoreaload"
                  class="d-flex"
                  :class="item.ouvrage.matiere.length > 31 ? 'pointeur' : ''"
                  v-if="item.ouvrage.matiere"
              >
                <span
                    v-if="!item.ouvrage.readMoreActivated"
                    @click="
                    item.ouvrage.readMoreActivated = true;
                    Keytoreaload = !Keytoreaload;
                  "
                >{{ item.ouvrage.matiere.slice(0, 30) }}
                  <!-- TODO -->
                  <span v-if="item.ouvrage.matiere.length > 31">...<v-icon small> mdi-information-outline </v-icon>
                  </span>
                </span>
                <span
                    v-if="item.ouvrage.readMoreActivated"
                    @click="
                    item.ouvrage.readMoreActivated = false;
                    Keytoreaload = !Keytoreaload;
                  "
                >
                  {{ item.ouvrage.matiere }}
                  <a v-if="
                      item.ouvrage.readMoreActivated &&
                      item.ouvrage.matiere.length > 30
                    ">
                    <v-icon> mdi-chevron-left </v-icon>
                  </a>
                </span>
              </div>
            </template>
            <!-- Action Button ------------------------------------------------------------------------------->
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon
                  color="success"
                  class="mr-2"
                  @click="addItem(item)"
                  v-if="!isInTab(item.refOffreDivalto)"
              >
                mdi-book-plus
              </v-icon>
              <v-icon
                  color="error"
                  class="mr-2"
                  @click="deleteItem(item)"
                  v-else
              >
                mdi-book-remove
              </v-icon>
            </template>

            <!-- Tableau  ------------------------------------------------------------------------------------>
            <template v-slot:top>
              <v-toolbar-title
                  class="mx-4"
                  v-if="!$vuetify.breakpoint.smAndUp"
              >Ouvrages
              </v-toolbar-title>
              <v-toolbar flat>
                <v-toolbar-title v-if="$vuetify.breakpoint.smAndUp">Ouvrages</v-toolbar-title>
                <v-divider
                    class="mx-4"
                    inset
                    vertical
                    v-if="$vuetify.breakpoint.smAndUp"
                ></v-divider>
                <v-spacer v-if="$vuetify.breakpoint.smAndUp"></v-spacer>
                <v-select
                    v-model="editeurSelect"
                    :items="editeurs"
                    label="Editeur"
                    single-line
                    hide-details
                    class="mx-4"
                    multiple
                    :disabled="editeur.length !=0 "
                ></v-select>
                <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Recherche"
                    single-line
                    hide-details
                    class="mx-4"
                ></v-text-field>
                <v-spacer v-if="$vuetify.breakpoint.smAndUp"></v-spacer>
              </v-toolbar>
            </template>
          </v-data-table>
        </v-card-text>
        <div class="text-center pa-4">
          <v-btn
              name="Fermer"
              class="justify-center"
              color="secondary"
              background-color="error"
              @click="dialog.value = false"
          >Fermer
          </v-btn>
        </div>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import AdminService from "../../services/AdminService";

export default {
  props: ["userBooks", "disable", "editeur", "action", "userEtab"],
  data() {
    return {
      searchTimer: undefined,
      editeurs: ["Lelivrescolaire", "Edulib", "kne", "CNS", "Educagri"],
      editeurSelect: this.editeur,
      Keytoreaload: true,
      itemsPerPageOptions: [10, 25, 50, 1000],
      itemsPerPageText: `Lignes par page`,
      dialog: false,
      userID: this.$route.params.id,
      search: "",
      libraryBooks: [],
      headers: [
        {text: "Titre", value: "ouvrage.nom", align: "start"},
        {text: "Référence", value: "refOffreDivalto"},
        {text: "Matière", value: "ouvrage.matiere"},
        {text: "Editeur", value: "ouvrage.editeurCatalog.codeEditeur"},
        {text: "Actions", value: "actions", sortable: false},
      ],
      Ishovered: true,
      boutonMove: "pr-4",
      page: 1,
      totalLibraryBooks: 0,
      numberOfPages: 0,
      pageCount: 0,
      loading: false,
      size: 10,
      options: {},
    };
  },

  watch: {
    search: function () {
      if(this.searchTimer) {
        clearTimeout(this.searchTimer)
      }
      this.searchTimer = setTimeout(() => {
        this.options.page = 1;
        this.initialize();
      }, 1000)
    },
    options: {
      handler() {
        this.initialize();
      },
    },
    editeurSelect: function () {
      this.initialize();
    },
    deep: true,
  },
  methods: {
    hovered() {
      this.Ishovered = false;
      this.boutonMove = "pr-2";
    },
    notHovered() {
      this.Ishovered = true;
      this.boutonMove = "pr-4";
    },
    arrayRefOffre() {
      return this.userBooks.map((book) => {
        return book.refOffreDivalto;
      });
    },
    isInTab(code) {
      return this.arrayRefOffre().includes(code);
    },
    initialize() {
      this.loading = true;
      const {page = 1, itemsPerPage, sortBy, sortDesc} = this.options;
      let pageNumber = page - 1;
      AdminService.searchBooks(
          pageNumber,
          itemsPerPage,
          sortBy,
          sortDesc,
          this.search,
          this.editeurSelect
      )
          .then((element) => {
            this.loading = false;
            this.libraryBooks = element.data.content;
            this.libraryBooks.forEach(({ouvrage}) => {
              //defini la variable readMoreActivated utilisée dans le component UserLibrary
              ouvrage.readMoreActivated = false;
              if (ouvrage.matiere) {
                [ouvrage.matiere] = ouvrage.matiere;
              }

            });
            this.totalLibraryBooks = element.data.totalElements;
            this.numberOfPages = element.data.totalPages;
          })
          .catch((error) => {
            console.log(error);
            this.$store.dispatch("setSnackbar", {
              color: "error",
              text: "Une erreur est survenue",
            });
            this.loading = false;
          });
    },

    addItem(item) {
      this.$emit("add", {book: item, etablissementId: this.userEtab?.id});
    },

    deleteItem(item) {
      this.$emit("delete", {book: item});
    },
  },
};
</script>

<style lang="scss" scoped>
.AccessButton {
  border-radius: 4px;
  font-weight: 800;
  letter-spacing: 0;
}

.pointeur {
  cursor: pointer;
}

.font {
  font-family: "dincondensed", sans-serif !important;
}
</style>
