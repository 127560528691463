<template>
  <v-dialog
      max-width="100%"
      v-model="dialog"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
          name="Ajouter un manuel"
          height="44px"
          color="primary"
          dark
          class="AccessButton pl-3 pr-1 "
          v-bind="attrs"
          v-on="on"
          tile
          elevation="0"
          fab
          width="auto"
          :outlined="Ishovered"
          @mouseover="hovered()"
          @mouseleave="notHovered()"
          :disabled="disable"
          @click="initialize()"
      >
        {{ action }}
        <v-spacer/>
        <v-icon
            class="ml-5"
            :class="boutonMove"
        >
          mdi-plus-circle-outline
        </v-icon>
      </v-btn>
    </template>
    <template
        color="white"
        v-slot:default="dialog"
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2 primary--text">
          <h1 class="text-h4 font text-break">
            Catalogue des références de l'établissement 
            <span v-if="userEtabs.length === 1">{{ idEtablissement }}</span>
          </h1>
          <v-select v-if="userEtabs.length !== 1" class="etabSelect text-h5 grey lighten-2 primary--text" :items="userEtabs" :value="idEtablissement" item-value="id" item-text="id"></v-select>
        </v-card-title>
        <v-card-text>
          <v-data-table
              :footer-props="{itemsPerPageOptions,itemsPerPageText}"
              :header-props="{ sortByText: `Trier par`}"
              :headers="headers"
              :items="libraryBooks"
              item-key="refOffreDivalto"
              class="elevation-0 ma-1 pa-1 mt-4"
              :pageCount="numberOfPages"
              :server-items-length="totalLibraryBooks"
              :loading="loading"
              loading-text="Chargement des éléments - Cette opération peut prendre quelques secondes"
              mobile-breakpoint="850"
              :search="search"
              :custom-filter="filterSearch"
          >
            <template v-slot:[`item.ouvrage.nom`]="{ item }">
              <span>{{ item.ouvrage.nom }}</span>
            </template>
            <!-- Matiere ------------------------------------------------------------------------------->
            <template v-slot:[`item.ouvrage.matiere`]="{ item }">
              <div
                  :key="Keytoreaload"
                  class="d-flex"
                  :class="item.ouvrage.matiere.length > 31 ? 'pointeur' : ''"
                  v-if="item.ouvrage.matiere"
              >
                <span
                    v-if="!item.ouvrage.readMoreActivated"
                    @click="
                    item.ouvrage.readMoreActivated = true;
                    Keytoreaload = !Keytoreaload;
                  "
                >{{ item.ouvrage.matiere.slice(0, 30) }}
                  <!-- TODO -->
                  <span v-if="item.ouvrage.matiere.length > 31">...<v-icon small> mdi-information-outline </v-icon>
                  </span>
                </span>
                <span
                    v-if="item.ouvrage.readMoreActivated"
                    @click="
                    item.ouvrage.readMoreActivated = false;
                    Keytoreaload = !Keytoreaload;
                  "
                >
                  {{ item.ouvrage.matiere }}
                  <a v-if="
                      item.ouvrage.readMoreActivated &&
                      item.ouvrage.matiere.length > 30
                    ">
                    <v-icon> mdi-chevron-left </v-icon>
                  </a>
                </span>
              </div>
            </template>
            <!-- Action Button ------------------------------------------------------------------------------->
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon
                  color="success"
                  class="mr-2"
                  @click="addItem(item)"
                  v-if="!isInTab(item.refOffreDivalto)"
              >
                mdi-book-plus
              </v-icon>
              <v-icon
                  color="error"
                  class="mr-2"
                  @click="deleteItem(item)"
                  v-else
              >
                mdi-book-remove
              </v-icon>
            </template>

            <!-- Tableau  ------------------------------------------------------------------------------------>
            <template v-slot:top>
              <v-toolbar-title
                  class="mx-4"
                  v-if="!$vuetify.breakpoint.smAndUp"
              >Ouvrages
              </v-toolbar-title>
              <v-toolbar flat>
                <v-toolbar-title v-if="$vuetify.breakpoint.smAndUp">Ouvrages</v-toolbar-title>
                <v-divider
                    class="mx-4"
                    inset
                    vertical
                    v-if="$vuetify.breakpoint.smAndUp"
                ></v-divider>
                <v-spacer v-if="$vuetify.breakpoint.smAndUp"></v-spacer>
                <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Recherche"
                    single-line
                    hide-details
                    class="mx-4"
                ></v-text-field>
                <v-spacer v-if="$vuetify.breakpoint.smAndUp"></v-spacer>
              </v-toolbar>
            </template>
          </v-data-table>
        </v-card-text>
        <div class="text-center pa-4">
          <v-btn
              name="Fermer"
              class="justify-center"
              color="secondary"
              background-color="error"
              @click="dialog.value = false"
          >Fermer
          </v-btn>
        </div>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import AdminService from "../../services/AdminService";

export default {
  props: ["userBooks", "disable", "editeur", "userEtabs", "action", "idUtilisateur"],
  data() {
    return {
      Keytoreaload: true,
      idEtablissement: this.userEtabs.length > 0 ? this.userEtabs[0].id : undefined,
      itemsPerPageOptions: [10, 25, 50, 1000],
      itemsPerPageText: `Lignes par page`,
      dialog: false,
      userID: this.$route.params.id,
      search: "",
      libraryBooks: [],
      headers: [
        {text: "Titre", value: "ouvrage.nom", align: "start"},
        {text: "Référence", value: "refOffreDivalto"},
        {text: "Matière", value: "ouvrage.matiere"},
        {text: "Durée de l'offre", value: "duration"},
        {text: "Cible", value: "ouvrage.cibleOuvrage"},
        {text: "Editeur", value: "ouvrage.editeurCatalog.codeEditeur"},
        {text: "Actions", value: "actions", sortable: false},
      ],
      Ishovered: true,
      boutonMove: "pr-4",
      page: 1,
      totalLibraryBooks: 0,
      numberOfPages: 0,
      pageCount: 0,
      loading: false,
      size: 10,
    };
  },

  methods: {
    hovered() {
      this.Ishovered = false;
      this.boutonMove = "pr-2";
    },
    notHovered() {
      this.Ishovered = true;
      this.boutonMove = "pr-4";
    },
    arrayRefOffre() {
      return this.userBooks.map((book) => {
        return book.refOffreDivalto;
      });
    },
    isInTab(code) {
      return this.arrayRefOffre().includes(code);
    },
    initialize() {
      this.loading = true;
      AdminService.searchEtabBooks(
          this.idEtablissement,
          this.idUtilisateur,
      )
          .then((element) => {
            this.loading = false;
            this.libraryBooks = element.data;
            this.libraryBooks.forEach(({ouvrage}) => {
              //defini la variable readMoreActivated utilisée dans le component UserLibrary
              ouvrage.readMoreActivated = false;
              [ouvrage.matiere] = ouvrage.matiere;
            });
            this.totalLibraryBooks = element.data.totalElements;
            this.numberOfPages = element.data.totalPages;
          })
          .catch((error) => {
            console.log(error);
            this.$store.dispatch("setSnackbar", {
              color: "error",
              text: "Une erreur est survenue",
            });
            this.loading = false;
          });
    },

    addItem(item) {
      this.$emit("add", {book: item, etablissementId: this.idEtablissement});
    },

    deleteItem(item) {
      this.$emit("delete", {book: item, etablissementId: this.idEtablissement});
    },
    filterSearch(value, search) {
      return value != null &&
          search != null &&
          typeof value === 'string' &&
          value.toString().toLocaleUpperCase().indexOf(search.toLocaleUpperCase()) !== -1
    }
  },
};
</script>

<style lang="scss" scoped>
.AccessButton {
  border-radius: 4px;
  font-weight: 800;
  letter-spacing: 0;
}

.etabSelect {
  margin-left: 16px;
  max-width: 200px;
}

.pointeur {
  cursor: pointer;
}

.font {
  font-family: "dincondensed", sans-serif !important;
}
</style>
