<template>
  <v-card
    class="pb-6 backgroundColor"
    height="100%"
  >
    <v-btn
      name="Retour"
      class="ma-6"
      text
      icon
      color="primary"
      @click="backTo()"
    >
      <v-icon size="60px">mdi-arrow-left-circle-outline</v-icon>
    </v-btn>
    <div class="Welcometitle primary--text titleFontlg">
      Manuels de l'utilisateur <span class="secondary--text">{{ user.identifiant }}</span><span class="primary--text"> {{ user.prenom }} {{ user.nom }} </span>
    </div>
    <UserLibrary
      v-if="user && user.etablissements.length > 0"
      :books="books"
      :loadingtab="loadingtab"
      :user="user"
    />
  </v-card>
</template>

<script>
import UserLibrary from "../components/UserLibraryVueComponent/UserLibrary.vue";
import AdminService from "./../services/AdminService";

export default {
  components: {
    UserLibrary,
  },
  data() {
    return {
      loadingtab: "",
      books: [],
      user: {
        identifiant: "",
        nom: "",
        prenom: "",
        emailContact: "",
        codeAdherent: "",
        role: "",
        actif: true,
        etablissements: [],
        classe: "",
        soutienBordas: false,
      },
    };
  },
  mounted() {
    AdminService.getUser(this.$route.params.id)
      .then((res) => {
        this.user = res.data;
        this.user.bibliotheque.forEach((book) => {
          //defini la variable readMoreActivated utilisée dans le component UserLibrary
          book.ouvrage.readMoreActivated = false;
          [book.ouvrage.matiere] = book.ouvrage.matiere;
          this.books.push(book);
        });
        this.loadingtab = "false";
        this.loadingtext = "Cet utilisateur ne possède pas de livre";
      })
      .catch((error) => console.log(error));
  },
  methods: {
    initialize() {
      AdminService.getUser(this.$route.params.id)
        .then((element) => (this.books = element.data))
        .catch((error) => {
          console.log(error);
          this.$store.dispatch("setSnackbar", {
            color: "error",
            text: "Une erreur est survenue",
          });
          this.loading = false;
        });
    },
    backTo() {
      this.$router.push({ path: `/admin/users/${this.user.identifiant}` }).catch((error) => {
        if (error.name !== "NavigationDuplicated") {
          throw error;
        }
      });
    },
  },
};
</script>
 <style scoped>
.subtitle {
  text-align: center;
  margin-bottom: 16px;
  color: #034155;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 24px;
}
.Welcometitle {
  text-align: center;
  margin-bottom: 16px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
}
.titleFontlg {
  font-size: 28px;
}
</style>
