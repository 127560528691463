var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"100%"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"AccessButton pl-3 pr-1",attrs:{"name":"Ajouter un manuel","height":"44px","color":"primary","dark":"","tile":"","elevation":"0","fab":"","width":"auto","outlined":_vm.Ishovered,"disabled":_vm.disable},on:{"mouseover":function($event){return _vm.hovered()},"mouseleave":function($event){return _vm.notHovered()}}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.action)+" "),_c('v-spacer'),_c('v-icon',{staticClass:"ml-5",class:_vm.boutonMove},[_vm._v(" mdi-plus-circle-outline ")])],1)]}},{key:"default",fn:function(dialog){return [_c('v-card',[_c('v-card-title',{staticClass:"text-h5 grey lighten-2 primary--text"},[_c('h1',{staticClass:"text-h4 font text-break"},[_vm._v(" Catalogue des références éditeurs ")])]),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-0 ma-1 pa-1 mt-4",attrs:{"footer-props":{itemsPerPageOptions: _vm.itemsPerPageOptions,itemsPerPageText: _vm.itemsPerPageText},"header-props":{ sortByText: "Trier par"},"headers":_vm.headers,"items":_vm.libraryBooks,"item-key":"refOffreDivalto","page":_vm.page,"pageCount":_vm.numberOfPages,"options":_vm.options,"server-items-length":_vm.totalLibraryBooks,"loading":_vm.loading,"loading-text":"Chargement des éléments","mobile-breakpoint":"850"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.ouvrage.nom",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.ouvrage.nom))])]}},{key:"item.ouvrage.matiere",fn:function(ref){
var item = ref.item;
return [(item.ouvrage.matiere)?_c('div',{key:_vm.Keytoreaload,staticClass:"d-flex",class:item.ouvrage.matiere.length > 31 ? 'pointeur' : ''},[(!item.ouvrage.readMoreActivated)?_c('span',{on:{"click":function($event){item.ouvrage.readMoreActivated = true;
                  _vm.Keytoreaload = !_vm.Keytoreaload;}}},[_vm._v(_vm._s(item.ouvrage.matiere.slice(0, 30))+" "),(item.ouvrage.matiere.length > 31)?_c('span',[_vm._v("..."),_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-information-outline ")])],1):_vm._e()]):_vm._e(),(item.ouvrage.readMoreActivated)?_c('span',{on:{"click":function($event){item.ouvrage.readMoreActivated = false;
                  _vm.Keytoreaload = !_vm.Keytoreaload;}}},[_vm._v(" "+_vm._s(item.ouvrage.matiere)+" "),(
                    item.ouvrage.readMoreActivated &&
                    item.ouvrage.matiere.length > 30
                  )?_c('a',[_c('v-icon',[_vm._v(" mdi-chevron-left ")])],1):_vm._e()]):_vm._e()]):_vm._e()]}},{key:"item.actions",fn:function(ref){
                  var item = ref.item;
return [(!_vm.isInTab(item.refOffreDivalto))?_c('v-icon',{staticClass:"mr-2",attrs:{"color":"success"},on:{"click":function($event){return _vm.addItem(item)}}},[_vm._v(" mdi-book-plus ")]):_c('v-icon',{staticClass:"mr-2",attrs:{"color":"error"},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-book-remove ")])]}},{key:"top",fn:function(){return [(!_vm.$vuetify.breakpoint.smAndUp)?_c('v-toolbar-title',{staticClass:"mx-4"},[_vm._v("Ouvrages ")]):_vm._e(),_c('v-toolbar',{attrs:{"flat":""}},[(_vm.$vuetify.breakpoint.smAndUp)?_c('v-toolbar-title',[_vm._v("Ouvrages")]):_vm._e(),(_vm.$vuetify.breakpoint.smAndUp)?_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}):_vm._e(),(_vm.$vuetify.breakpoint.smAndUp)?_c('v-spacer'):_vm._e(),_c('v-select',{staticClass:"mx-4",attrs:{"items":_vm.editeurs,"label":"Editeur","single-line":"","hide-details":"","multiple":"","disabled":_vm.editeur.length !=0},model:{value:(_vm.editeurSelect),callback:function ($$v) {_vm.editeurSelect=$$v},expression:"editeurSelect"}}),_c('v-text-field',{staticClass:"mx-4",attrs:{"append-icon":"mdi-magnify","label":"Recherche","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),(_vm.$vuetify.breakpoint.smAndUp)?_c('v-spacer'):_vm._e()],1)]},proxy:true}],null,true)})],1),_c('div',{staticClass:"text-center pa-4"},[_c('v-btn',{staticClass:"justify-center",attrs:{"name":"Fermer","color":"secondary","background-color":"error"},on:{"click":function($event){dialog.value = false}}},[_vm._v("Fermer ")])],1)],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}})}
var staticRenderFns = []

export { render, staticRenderFns }