<template>
  <v-card
      class="mx-auto"
      width="80%"
      max-width="2000px"
      elevation="1"
  >
    <v-row class="mx-auto pa-4 pb-0 text-right">

      <v-col cols="auto" class=" pt-5 text-h5"> Manuels associés</v-col>
      <v-divider
          class="mx-4 "
          inset
          vertical
          v-if="$vuetify.breakpoint.lgAndUp"
      ></v-divider>


      <v-spacer></v-spacer>
      <v-col cols="12" md="auto" class="md-ml-auto ">
        <EtabBooksTab
            action="Catalogue Etablissement"
            :userBooks="books"
            @delete="catchDeleteEmit"
            @add="catchAddEmit"
          editeur=""
            :userEtabs="user.etablissements"
          :disable="userConnected.role === 'GESTIONNAIRE_ETABLISSEMENT' || userConnected.role === 'GESTIONNAIRE_ETABLISSEMENT_AVANCE'"
          :idUtilisateur="user.identifiant"
        />
      </v-col>
      <v-col md="auto" cols="12">
        <BooksTab
            action="Catalogue général"
            :userBooks="books"
            :userEtab="user.etablissements[0]"
          :disable="userConnected.role === 'GESTIONNAIRE_ETABLISSEMENT' || userConnected.role === 'GESTIONNAIRE_ETABLISSEMENT_AVANCE'"
          @delete="catchDeleteEmit"
            @add="catchAddEmit"
            editeur=""
        />
      </v-col>
    </v-row>
    <!-- Tableau  ------------------------------------------------------------------------------------>
    <div class="px-5 py-7">
      <v-data-table
          :footer-props="{itemsPerPageOptions,itemsPerPageText}"
          :header-props="{ sortByText: `Trier par`}"
        :headers="headers"
          :items="books"
          item-key="refOffreDivalto"
          sort-by="titre"
          class="elevation-0"
        :loading="loadingtab"
          :loading-text="loadingtab ? `Aucun manuel n'est associé à ce compte` : loadingtext"
        :single-expand="singleExpand"
          :expanded.sync="expanded"
          show-expand
          mobile-breakpoint="850"
      >

        <!-- Matiere ------------------------------------------------------------------------------->
        <template v-slot:[`item.ouvrage.matiere`]="{ item }">
          <div
              v-if="item.ouvrage.matiere !== undefined"
              class="d-flex"
              :class="item.ouvrage.matiere.length>31 ? 'pointeur':''"
          >
            <span
                v-if="!item.ouvrage.readMoreActivated"
                @click="item.ouvrage.readMoreActivated = true"
            >{{ item.ouvrage.matiere.slice(0, 30) }}
              <span v-if="item.ouvrage.matiere.length > 30">...<v-icon small> mdi-information-outline </v-icon>
              </span></span>
            <span
                v-if="item.ouvrage.readMoreActivated"
                @click="item.ouvrage.readMoreActivated = false"
            >
              {{ item.ouvrage.matiere }}
              <a v-if="item.ouvrage.readMoreActivated&&item.ouvrage.matiere.length > 30">
                <v-icon> mdi-chevron-left </v-icon>
              </a>
            </span>
          </div>
        </template>
        <!-- Action Button ------------------------------------------------------------------------------->
        <template v-slot:[`item.actions`]="{ item }">
          <v-row>
            <Tooltip textToolTip="Supprimer un ouvrage de la bibliothèque.">
              <v-icon
                  class="mr-2 d-flex justify-center"
                  color="error"
                :disabled="userConnected.role === 'GESTIONNAIRE_ETABLISSEMENT' || userConnected.role === 'GESTIONNAIRE_ETABLISSEMENT_AVANCE'"
                @click="deleteItem(item)"
              >
                mdi-delete
              </v-icon>
            </Tooltip>
            <Tooltip textToolTip="Activer/désactiver un ouvrage.">
              <v-icon
                  class="mr-2 d-flex justify-center"
                  color="primary"
                :disabled="userConnected.role === 'GESTIONNAIRE_ETABLISSEMENT' || userConnected.role === 'GESTIONNAIRE_ETABLISSEMENT_AVANCE'"
                @click="enableDisableItem(item)"
              >
                mdi-book-lock
              </v-icon>
            </Tooltip>
          </v-row>
        </template>

        <!-- Menu déroulant ------------------------------------------------------------------------------->
        <template v-slot:expanded-item="{ headers, item }">
          <div :colspan="headers.length">
            <v-row no-gutters>
              <v-img
                  height="150"
                  width="100"
                  :src="item. ouvrage.imgCouverture"
                  aspect-ratio="9 / 16"
                  contain
              />
            </v-row>
          </div>
        </template>

        <template v-slot:[`item.webCommandeId`]="props" v-if="$store.state.user.role !== 'GESTIONNAIRE_ETABLISSEMENT_AVANCE'">
          <v-edit-dialog
              lazy
              :return-value.sync="props.item.webCommandeId"
              large
              @save="saveWebCommande(props.item)"
            @cancel="cancelWebCommande"
              save-text="Valider"
              cancel-text="Annuler"
          >
            {{ props.item.webCommandeId }}
            <template v-slot:input>
              <v-form
                  ref="form"
                  v-model="validInput"
              >
                <v-text-field
                    v-model="props.item.webCommandeId"
                    label="Modifier"
                    type="number"
                  single-line
                ></v-text-field>
              </v-form>
            </template>
          </v-edit-dialog>
        </template>

        <template v-slot:[`item.status`]="{ item }">
          <div :style="{ 'min-width': 45 + 'px' }">
            <v-icon
                class="mr-2"
                :color="getColor(item.status)"
            >
              {{ actifText(item.status) }}
            </v-icon>
          </div>
        </template>
        <!-- tableau sans data ------------------------------------------------------------------------------->
        <template v-slot:no-data>
          <v-btn
              name="Rafraichir"
              color="primary"
              @click="initialize"
          > Reset
          </v-btn>
        </template>
      </v-data-table>
      <!-- Modal DELETE BOOKS---------------------------------------------------------------------------->
      <ValidationModal
          text="Êtes-vous sûr de bien vouloir retirer ce livre à cet utilisateur?"
          :status="dialogDelete"
        @validated="deleteItemConfirm()"
          @Canceled="closeDelete()"
      />
    </div>
  </v-card>
</template>

<script>
import ValidationModal from "../Utility/ValidationModal.vue";
import BooksTab from "../Utility/BooksTab.vue";
import EtabBooksTab from "../Utility/EtabBooksTab.vue";
import AdminService from "../../services/AdminService";
import Tooltip from "../Utility/Tooltip.vue";

export default {
  data() {
    return {
      expanded: [],
      validInput: false,
      singleExpand: true,
      itemsPerPageOptions: [10, 25, 50, 1000],
      itemsPerPageText: `Lignes par page`,
      dialogDelete: false,
      loadingtext: "Nous chargeons votre liste d'ouvrages, merci de patienter",
      headers: [
        { text: "Titre", value: "ouvrage.nom", align: "start" },
        { text: "Référence", value: "refOffreDivalto" },
        { text: "Matière", value: "ouvrage.matiere" },
        { text: "Actif", value: "status" },
        { text: "Commande", value: "webCommandeId" },
        { text: "Actions", value: "actions", sortable: false, align: "center" },
      ],
      editedIndex: -1,
      editedItem: {
        ouvrage: {
          nom: "",
          id: "",
          matiere: "",
          ean: "",
        },
      },
      editeurSelect: "",
    };
  },
  components: {
    ValidationModal,
    BooksTab,
    Tooltip,
    EtabBooksTab,
  },
  props: ["books", "loadingtab", "user"],
  computed: {
    userConnected() {
      return this.$store.state.user;
    },
  },
  methods: {
    actifText(item) {
      if (item === "ACTIF") {
        return "mdi-check-circle-outline";
      } else if (item === 'EN_ATTENTE') {
        return "mdi-clock-outline";
      } else {
        return "mdi-close-circle-outline";
      }
    },
    getColor(Status) {
      if (Status === "ACTIF") return "green";
      else if (Status === "EN_ATTENTE") return "warning";
      else return "secondary";
    },
    deleteItem(item) {
      this.editedIndex = this.books.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    enableDisableItem(item) {
      this.editedIndex = this.books.indexOf(item);
      this.editedItem = Object.assign({}, item);
      AdminService.enableDisableBook(
        this.user.identifiant,
        this.editedItem.refOffreDivalto
      ).then(() => {
        this.books[this.editedIndex].status =
          this.editedItem.status === "ACTIF" ? "INACTIF" : "ACTIF";
      });
    },
    catchDeleteEmit(payload) {
      return AdminService.deleteBook(
        this.$route.params.id,
        payload.book.refOffreDivalto,
        payload.etablissementId
      ).then(
        this.books.splice(
          this.books.findIndex(
            (v) => v.refOffreDivalto === payload.book.refOffreDivalto
          ),
          1
        )
      );
    },
    catchAddEmit(payload) {
      return AdminService.addBook(
        this.$route.params.id,
        payload.book.refOffreDivalto,
        payload.etablissementId
      ).then(() => {
        payload.book.status = "ACTIF";
        this.books.push(payload.book);
      }).catch((error) => {
        if (error) {
          this.$store.dispatch("setSnackbar", {
            color: "error",
            text: "Cet ouvrage est déjà présent dans la bibliothèque de l'utilisateur.",
          });
          this.loading = false;
        }
      });
    },
    deleteItemConfirm() {
      AdminService.deleteBook(
        this.user.identifiant,
        this.editedItem.refOffreDivalto
      ).then(() => {
        this.books.splice(this.editedIndex, 1);
        this.closeDelete();
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    cancelWebCommande() {
      this.snack = true;
      this.snackColor = "error";
      this.snackText = "Modification annulé";
    },
    saveWebCommande(item) {
      this.$refs.form.validate();
      if (this.validInput) {
        this.snack = true;
        AdminService.editWebCommandeId(
          this.user.identifiant,
          item.refOffreDivalto,
          item.webCommandeId
        ).then(() => {
          this.$store.dispatch("setSnackbar", {
            color: "success",
            text: "Modification sauvegardé",
          });
        }).catch((error) => {
          if (error) {
            // si l'erreur est 409 (conflit) alors on affiche un message d'erreur
            if (error.response.status === 409) {
              this.$store.dispatch("setSnackbar", {
                color: "error",
                text: "Cette commande à déjà été activé.",
              });
            } else {
              this.$store.dispatch("setSnackbar", {
                color: "error",
                text: "Une erreur est survenue",
              });
            }
          }
        });
      } else {
        this.initialize();
      }
    }
  },
};
</script>
<style lang="scss" scoped>
.pointeur {
  cursor: pointer;
}
</style>
