var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mx-auto",attrs:{"width":"80%","max-width":"2000px","elevation":"1"}},[_c('v-row',{staticClass:"mx-auto pa-4 pb-0 text-right"},[_c('v-col',{staticClass:" pt-5 text-h5",attrs:{"cols":"auto"}},[_vm._v(" Manuels associés")]),(_vm.$vuetify.breakpoint.lgAndUp)?_c('v-divider',{staticClass:"mx-4 ",attrs:{"inset":"","vertical":""}}):_vm._e(),_c('v-spacer'),_c('v-col',{staticClass:"md-ml-auto ",attrs:{"cols":"12","md":"auto"}},[_c('EtabBooksTab',{attrs:{"action":"Catalogue Etablissement","userBooks":_vm.books,"editeur":"","userEtabs":_vm.user.etablissements,"disable":_vm.userConnected.role === 'GESTIONNAIRE_ETABLISSEMENT' || _vm.userConnected.role === 'GESTIONNAIRE_ETABLISSEMENT_AVANCE',"idUtilisateur":_vm.user.identifiant},on:{"delete":_vm.catchDeleteEmit,"add":_vm.catchAddEmit}})],1),_c('v-col',{attrs:{"md":"auto","cols":"12"}},[_c('BooksTab',{attrs:{"action":"Catalogue général","userBooks":_vm.books,"userEtab":_vm.user.etablissements[0],"disable":_vm.userConnected.role === 'GESTIONNAIRE_ETABLISSEMENT' || _vm.userConnected.role === 'GESTIONNAIRE_ETABLISSEMENT_AVANCE',"editeur":""},on:{"delete":_vm.catchDeleteEmit,"add":_vm.catchAddEmit}})],1)],1),_c('div',{staticClass:"px-5 py-7"},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"footer-props":{itemsPerPageOptions: _vm.itemsPerPageOptions,itemsPerPageText: _vm.itemsPerPageText},"header-props":{ sortByText: "Trier par"},"headers":_vm.headers,"items":_vm.books,"item-key":"refOffreDivalto","sort-by":"titre","loading":_vm.loadingtab,"loading-text":_vm.loadingtab ? "Aucun manuel n'est associé à ce compte" : _vm.loadingtext,"single-expand":_vm.singleExpand,"expanded":_vm.expanded,"show-expand":"","mobile-breakpoint":"850"},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.ouvrage.matiere",fn:function(ref){
var item = ref.item;
return [(item.ouvrage.matiere !== undefined)?_c('div',{staticClass:"d-flex",class:item.ouvrage.matiere.length>31 ? 'pointeur':''},[(!item.ouvrage.readMoreActivated)?_c('span',{on:{"click":function($event){item.ouvrage.readMoreActivated = true}}},[_vm._v(_vm._s(item.ouvrage.matiere.slice(0, 30))+" "),(item.ouvrage.matiere.length > 30)?_c('span',[_vm._v("..."),_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-information-outline ")])],1):_vm._e()]):_vm._e(),(item.ouvrage.readMoreActivated)?_c('span',{on:{"click":function($event){item.ouvrage.readMoreActivated = false}}},[_vm._v(" "+_vm._s(item.ouvrage.matiere)+" "),(item.ouvrage.readMoreActivated&&item.ouvrage.matiere.length > 30)?_c('a',[_c('v-icon',[_vm._v(" mdi-chevron-left ")])],1):_vm._e()]):_vm._e()]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-row',[_c('Tooltip',{attrs:{"textToolTip":"Supprimer un ouvrage de la bibliothèque."}},[_c('v-icon',{staticClass:"mr-2 d-flex justify-center",attrs:{"color":"error","disabled":_vm.userConnected.role === 'GESTIONNAIRE_ETABLISSEMENT' || _vm.userConnected.role === 'GESTIONNAIRE_ETABLISSEMENT_AVANCE'},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")])],1),_c('Tooltip',{attrs:{"textToolTip":"Activer/désactiver un ouvrage."}},[_c('v-icon',{staticClass:"mr-2 d-flex justify-center",attrs:{"color":"primary","disabled":_vm.userConnected.role === 'GESTIONNAIRE_ETABLISSEMENT' || _vm.userConnected.role === 'GESTIONNAIRE_ETABLISSEMENT_AVANCE'},on:{"click":function($event){return _vm.enableDisableItem(item)}}},[_vm._v(" mdi-book-lock ")])],1)],1)]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('div',{attrs:{"colspan":headers.length}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-img',{attrs:{"height":"150","width":"100","src":item. ouvrage.imgCouverture,"aspect-ratio":"9 / 16","contain":""}})],1)],1)]}},(_vm.$store.state.user.role !== 'GESTIONNAIRE_ETABLISSEMENT_AVANCE')?{key:"item.webCommandeId",fn:function(props){return [_c('v-edit-dialog',{attrs:{"lazy":"","return-value":props.item.webCommandeId,"large":"","save-text":"Valider","cancel-text":"Annuler"},on:{"update:returnValue":function($event){return _vm.$set(props.item, "webCommandeId", $event)},"update:return-value":function($event){return _vm.$set(props.item, "webCommandeId", $event)},"save":function($event){return _vm.saveWebCommande(props.item)},"cancel":_vm.cancelWebCommande},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-form',{ref:"form",model:{value:(_vm.validInput),callback:function ($$v) {_vm.validInput=$$v},expression:"validInput"}},[_c('v-text-field',{attrs:{"label":"Modifier","type":"number","single-line":""},model:{value:(props.item.webCommandeId),callback:function ($$v) {_vm.$set(props.item, "webCommandeId", $$v)},expression:"props.item.webCommandeId"}})],1)]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(props.item.webCommandeId)+" ")])]}}:null,{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('div',{style:({ 'min-width': 45 + 'px' })},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":_vm.getColor(item.status)}},[_vm._v(" "+_vm._s(_vm.actifText(item.status))+" ")])],1)]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"name":"Rafraichir","color":"primary"},on:{"click":_vm.initialize}},[_vm._v(" Reset ")])]},proxy:true}],null,true)}),_c('ValidationModal',{attrs:{"text":"Êtes-vous sûr de bien vouloir retirer ce livre à cet utilisateur?","status":_vm.dialogDelete},on:{"validated":function($event){return _vm.deleteItemConfirm()},"Canceled":function($event){return _vm.closeDelete()}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }